import { convertCGMEntriesToReadings } from "../../../../../../../../utils/glucose-point-utils/convert-cgm-entries-to-readings";
import { calculateLowPercentage } from "../../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-low-percentage";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import { GroupCgmEntriesByDate } from "./build-group-cgm-entires-by-date";

export function lowPercentageValue(
  groupCgmEntriesByDate: GroupCgmEntriesByDate[],
  minRange: number
) {
  const lowTotal = groupCgmEntriesByDate.reduce(
    (sum: number, item: GroupCgmEntriesByDate) => {
      const lowPercentageTotal = calculateLowPercentage(
        convertCGMEntriesToReadings(item.readings),
        minRange
      );

      return (sum += lowPercentageTotal);
    },
    0
  );

  const lowPercentage = roundTo1DecimalPlace(
    lowTotal / groupCgmEntriesByDate.length
  );

  return lowPercentage;
}
