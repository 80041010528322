import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { PatientCGMEntryDTO } from "../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";
import { calculateInRangePercentage } from "../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-in-range-percentage";
import { calculateVeryHighPercentage } from "../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-very-high-percentage";
import { calculateHighPercentage } from "../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-high-percentage";
import { getInRangeNumbers } from "../../../../../utils/patient-type-utils";
import { calculateLowPercentage } from "../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-low-percentage";
import { calculateVeryLowPercentage } from "../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-very-low-percentage";
import { convertCGMEntriesToReadings } from "../../../../../utils/glucose-point-utils/convert-cgm-entries-to-readings";
import { roundTo1DecimalPlace } from "../../../../../utils/math-utils";

export type GroupCgmEntriesByDate = {
  date: string;
  readings: PatientCGMEntryDTO[];
};

export type AGPReportLoadedResponse = {
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
};

type TAGPReportLoadedResponse = {
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  groupCgmEntriesByDate: GroupCgmEntriesByDate[];
  veryHighPercentage: number;
  highPercentage: number;
  targetPercentage: number;
  lowPercentage: number;
  veryLowPercentage: number;
};

const AGPReportLoadedResponseContext = createContext<
  TAGPReportLoadedResponse | undefined
>(undefined);

type AGPReportLoadedResponseProviderProps = PropsWithChildren<{
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
}>;

export function AGPReportLoadedResponseProvider(
  props: AGPReportLoadedResponseProviderProps
) {
  const { children, patientDTO, patientCGMEntryDTOs } = props;

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const { groupCgmEntriesByDate } = useMemo(() => {
    const groupCgmEntriesByDate: GroupCgmEntriesByDate[] = [];

    for (const entry of patientCGMEntryDTOs) {
      const existingEntry = groupCgmEntriesByDate.find(
        (e) => e.date === entry.date
      );

      if (existingEntry) {
        existingEntry.readings.push(entry);
      } else {
        groupCgmEntriesByDate.push({
          date: entry.date,
          readings: [entry],
        });
      }
    }

    return { groupCgmEntriesByDate };
  }, [patientCGMEntryDTOs]);

  const { veryHighPercentage } = useMemo(() => {
    const veryHighTotal = groupCgmEntriesByDate.reduce(
      (sum: number, item: GroupCgmEntriesByDate) => {
        const veryHighPercentageTotal = calculateVeryHighPercentage(
          convertCGMEntriesToReadings(item.readings)
        );

        return (sum += veryHighPercentageTotal);
      },
      0
    );

    const veryHighPercentage = roundTo1DecimalPlace(
      veryHighTotal / groupCgmEntriesByDate.length
    );

    return { veryHighPercentage };
  }, [groupCgmEntriesByDate]);

  const { highPercentage } = useMemo(() => {
    const highTotal = groupCgmEntriesByDate.reduce(
      (sum: number, item: GroupCgmEntriesByDate) => {
        const highPercentageTotal = calculateHighPercentage(
          convertCGMEntriesToReadings(item.readings),
          maxRange
        );

        return (sum += highPercentageTotal);
      },
      0
    );

    const highPercentage = roundTo1DecimalPlace(
      highTotal / groupCgmEntriesByDate.length
    );

    return { highPercentage };
  }, [groupCgmEntriesByDate, maxRange]);

  const { targetPercentage } = useMemo(() => {
    const inRangesTotal = groupCgmEntriesByDate.reduce(
      (sum: number, item: GroupCgmEntriesByDate) => {
        const inRangePercentageTotal = calculateInRangePercentage(
          patientDTO.type,
          convertCGMEntriesToReadings(item.readings)
        );

        return (sum += inRangePercentageTotal);
      },
      0
    );

    const targetPercentage = roundTo1DecimalPlace(
      inRangesTotal / groupCgmEntriesByDate.length
    );

    return { targetPercentage };
  }, [groupCgmEntriesByDate, patientDTO.type]);

  const { lowPercentage } = useMemo(() => {
    const lowTotal = groupCgmEntriesByDate.reduce(
      (sum: number, item: GroupCgmEntriesByDate) => {
        const lowPercentageTotal = calculateLowPercentage(
          convertCGMEntriesToReadings(item.readings),
          minRange
        );

        return (sum += lowPercentageTotal);
      },
      0
    );

    const lowPercentage = roundTo1DecimalPlace(
      lowTotal / groupCgmEntriesByDate.length
    );

    return { lowPercentage };
  }, [groupCgmEntriesByDate, minRange]);

  const { veryLowPercentage } = useMemo(() => {
    const veryLowTotal = groupCgmEntriesByDate.reduce(
      (sum: number, item: GroupCgmEntriesByDate) => {
        const veryLowPercentageTotal = calculateVeryLowPercentage(
          convertCGMEntriesToReadings(item.readings)
        );

        return (sum += veryLowPercentageTotal);
      },
      0
    );

    const veryLowPercentage = roundTo1DecimalPlace(
      veryLowTotal / groupCgmEntriesByDate.length
    );

    return { veryLowPercentage };
  }, [groupCgmEntriesByDate]);

  const value = useMemo(
    () => ({
      patientDTO,
      patientCGMEntryDTOs,
      groupCgmEntriesByDate,
      veryHighPercentage,
      highPercentage,
      targetPercentage,
      lowPercentage,
      veryLowPercentage,
    }),
    [
      patientDTO,
      patientCGMEntryDTOs,
      groupCgmEntriesByDate,
      veryHighPercentage,
      highPercentage,
      targetPercentage,
      lowPercentage,
      veryLowPercentage,
    ]
  );

  return (
    <AGPReportLoadedResponseContext.Provider value={value}>
      {children}
    </AGPReportLoadedResponseContext.Provider>
  );
}

export function useAGPReportLoadedResponse(): TAGPReportLoadedResponse {
  const AGPReportLoadedResponse = useContext(AGPReportLoadedResponseContext);

  if (AGPReportLoadedResponse === undefined) {
    throw new Error(
      "useAGPReportLoadedResponse must be used within a AGPReportLoadedResponseProvider"
    );
  }

  return AGPReportLoadedResponse;
}
