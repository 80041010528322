import { useParams } from "react-router-dom";
import { Loading } from "../../../../../components/loading";
import { AGPReportResponseCards } from "./agp-report-response-cards/agp-report-response-cards";
import { useGetPatient } from "../../../../../hooks/patient-hooks/use-get-patient";
import { useGetPatientCGMEntries } from "../../../../../hooks/use-get-patient-cgm-entries";
import { useCallback } from "react";
import { AGPReportResponceCharts } from "./agp-report-response-charts/agp-report-response-charts";
import { AGPLoadedResponseProvider } from "../context/agp-loaded-response-context";
import { AGPReportResponseAGP } from "./agp-report-response-agp/agp-report-response-agp";
import { useDateRange } from "../../../../../components/date-range/context/date-range-context";
import {
  AGPReportLoadedResponse,
  AGPReportLoadedResponseProvider,
} from "../context/agp-report-loaded-response-context";
import { AGPReportResponseHeader } from "./agp-report-response-header/agp-report-response-header";

export const AGPReportResponse = () => {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();
  const getPatientCGMEntries = useGetPatientCGMEntries();

  const { temporalStartDate, temporalEndDate } = useDateRange();

  const cgmReportResponseLoadCallback = useCallback(async (): Promise<
    AGPReportLoadedResponse
  > => {
    const patientDTO = await getPatient(patientID);

    const cgmSource = "Libreview";
    const patientCGMEntryDTOs = await getPatientCGMEntries(
      patientID,
      cgmSource,
      temporalStartDate,
      temporalEndDate
    );

    return {
      patientDTO,
      patientCGMEntryDTOs,
    };
  }, [
    patientID,
    getPatient,
    getPatientCGMEntries,
    temporalStartDate,
    temporalEndDate,
  ]);

  return (
    <Loading
      load={cgmReportResponseLoadCallback}
      successComponent={(cgmReportResponse) => (
        <AGPReportLoadedResponseProvider
          patientDTO={cgmReportResponse.patientDTO}
          patientCGMEntryDTOs={cgmReportResponse.patientCGMEntryDTOs}
        >
          <AGPReportResponseHeader />
          <AGPLoadedResponseProvider>
            <AGPReportResponseCards />
            <AGPReportResponseAGP />
            <AGPReportResponceCharts />
          </AGPLoadedResponseProvider>
        </AGPReportLoadedResponseProvider>
      )}
    />
  );
};
