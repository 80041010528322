import {
  AGPReportResponseAGPBody,
  AGPReportResponseAGPCard,
  AGPReportResponseAGPHeader,
  AGPReportResponseAGPContainer,
} from "./styled-agp-report-response-agp";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { useAGPReportLoadedResponse } from "../../context/agp-report-loaded-response-context";
import { AGPReportResponseAGPGraph } from "./agp-report-response-agp-graph/agp-report-response-agp-graph";

export const AGPReportResponseAGP = () => {
  const { groupCgmEntriesByDate } = useAGPReportLoadedResponse();

  if (groupCgmEntriesByDate.length === 0) {
    return (
      <AGPReportResponseAGPContainer>
        <AGPReportResponseAGPCard>
          <AGPReportResponseAGPHeader>
            <p>AGP Graph</p>
          </AGPReportResponseAGPHeader>
          <AGPReportResponseAGPBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="No Data Within the selected dates"
              title=""
            />
          </AGPReportResponseAGPBody>
        </AGPReportResponseAGPCard>
      </AGPReportResponseAGPContainer>
    );
  }

  if (groupCgmEntriesByDate.length < 14) {
    return (
      <AGPReportResponseAGPContainer>
        <AGPReportResponseAGPCard>
          <AGPReportResponseAGPHeader>
            <p>AGP Graph</p>
          </AGPReportResponseAGPHeader>
          <AGPReportResponseAGPBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="Please select a date range of 14 days or more to proceed."
              title=""
            />
          </AGPReportResponseAGPBody>
        </AGPReportResponseAGPCard>
      </AGPReportResponseAGPContainer>
    );
  }

  return (
    <AGPReportResponseAGPContainer>
      <AGPReportResponseAGPCard>
        <AGPReportResponseAGPHeader>
          <p>AGP Graph</p>
        </AGPReportResponseAGPHeader>
        <AGPReportResponseAGPBody $isEmpty={false}>
          <AGPReportResponseAGPGraph />
        </AGPReportResponseAGPBody>
      </AGPReportResponseAGPCard>
    </AGPReportResponseAGPContainer>
  );
};
