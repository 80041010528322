import styled from "styled-components/macro";
import { SinglePatientSidebarWidth } from "../../../../../../../styles/global-style";

export const AGPReportResponseAGPGraphContainer = styled.div`
  margin: 0 auto;
  max-width: 929px;
  width: calc(100vw - (${SinglePatientSidebarWidth}));

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
