import { useMemo } from "react";
import {
  AGPReportResponseGlucoseStatisticsBody,
  AGPReportResponseGlucoseStatisticsBodyItem,
  AGPReportResponseGlucoseStatisticsBodyItemTitle,
  AGPReportResponseGlucoseStatisticsContainer,
  AGPReportResponseGlucoseStatisticsHeader,
} from "./styled-agp-report-response-glucose-statistics";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { useAGPReportLoadedResponse } from "../../../context/agp-report-loaded-response-context";
import { useDateRange } from "../../../../../../../components/date-range/context/date-range-context";

export const AGPReportResponseGlucoseStatistics = () => {
  const {
    patientCGMEntryDTOs,
    groupCgmEntriesByDate,
  } = useAGPReportLoadedResponse();
  const { selectedDates } = useDateRange();

  const { average } = useMemo(() => {
    let total = 0;

    for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
      const glucoseReading = patientCGMEntryDTO.glucoseMGPerDL;

      total += glucoseReading;
    }

    const average = roundTo1DecimalPlace(total / patientCGMEntryDTOs.length);

    return { average };
  }, [patientCGMEntryDTOs]);

  const { gmi, warningGMIMessage } = useMemo(() => {
    let warningGMIMessage: string = "";

    // 3.31 + 0.02392 * average
    const glycatedAlbumin = 3.31;
    const glycatedProteins = 0.02392;

    if (selectedDates.count < 14) {
      warningGMIMessage =
        "Can't be calculated, date range is less than 14 days!";
    }

    const glycatedProteinsByAverage = glycatedProteins * average;
    const gmi = glycatedAlbumin + glycatedProteinsByAverage;

    return { gmi, warningGMIMessage };
  }, [selectedDates.count, average]);

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <AGPReportResponseGlucoseStatisticsContainer>
        <AGPReportResponseGlucoseStatisticsHeader>
          <p>Glucose Statistics</p>
        </AGPReportResponseGlucoseStatisticsHeader>
        <AGPReportResponseGlucoseStatisticsBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </AGPReportResponseGlucoseStatisticsBody>
      </AGPReportResponseGlucoseStatisticsContainer>
    );
  }

  if (groupCgmEntriesByDate.length < 14) {
    return (
      <AGPReportResponseGlucoseStatisticsContainer>
        <AGPReportResponseGlucoseStatisticsHeader>
          <p>Glucose Statistics</p>
        </AGPReportResponseGlucoseStatisticsHeader>
        <AGPReportResponseGlucoseStatisticsBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="Please select a date range of 14 days or more to proceed."
            title=""
          />
        </AGPReportResponseGlucoseStatisticsBody>
      </AGPReportResponseGlucoseStatisticsContainer>
    );
  }

  return (
    <AGPReportResponseGlucoseStatisticsContainer>
      <AGPReportResponseGlucoseStatisticsHeader>
        <p>Glucose Statistics</p>
      </AGPReportResponseGlucoseStatisticsHeader>
      <AGPReportResponseGlucoseStatisticsBody>
        <AGPReportResponseGlucoseStatisticsBodyItem>
          <AGPReportResponseGlucoseStatisticsBodyItemTitle>
            Average Glucose
          </AGPReportResponseGlucoseStatisticsBodyItemTitle>
          <span>
            {average} <span>mg/dl</span>
          </span>
        </AGPReportResponseGlucoseStatisticsBodyItem>
        <AGPReportResponseGlucoseStatisticsBodyItem>
          <AGPReportResponseGlucoseStatisticsBodyItemTitle>
            Glucose Management Indicator (GMI)
            <span>
              Approximate A1C level based in average CGM Glucose level.
            </span>
          </AGPReportResponseGlucoseStatisticsBodyItemTitle>
          {selectedDates.count < 14 ? (
            <strong>{warningGMIMessage}</strong>
          ) : (
            <span>{roundTo1DecimalPlace(gmi)}%</span>
          )}
        </AGPReportResponseGlucoseStatisticsBodyItem>
      </AGPReportResponseGlucoseStatisticsBody>
    </AGPReportResponseGlucoseStatisticsContainer>
  );
};
