import { Outlet, useParams } from "react-router-dom";
import {
  ScreenSubNavbar,
  SubNavLinksType,
} from "../../../components/screen-sub-navbar/screen-sub-navbar";
import { SubScreenContainer } from "../../../styles/classes/reusable-classes";
import {
  FEATURE_FLAG_APEX_CHART,
  FEATURE_FLAG_BGM_SUBTAB,
  FEATURE_FLAG_CGM_REPORT,
  FEATURE_FLAG_DAILY_FIXED_SUMMARY,
} from "../../../constants/config";

export const BglAnalysis = () => {
  const { id, page } = useParams();

  return (
    <>
      <ScreenSubNavbar links={buildBglNavLinks(id!, page!)} />
      <SubScreenContainer>
        <Outlet />
      </SubScreenContainer>
    </>
  );
};

function buildBglNavLinks(id: string, page: string): SubNavLinksType {
  const navLinks: SubNavLinksType = [
    {
      name: "Daily analysis",
      path: `/patients/${id}/bgl-analysis/daily-analysis/${
        page !== undefined ? page : 1
      }`,
    },
    {
      name: "Meals Analysis",
      path: `/patients/${id}/bgl-analysis/meals-analysis`,
    },
  ];

  if (FEATURE_FLAG_CGM_REPORT) {
    navLinks.push({
      name: "AGP Report",
      path: `/patients/${id}/bgl-analysis/agp-report`,
    });
  }

  if (FEATURE_FLAG_DAILY_FIXED_SUMMARY) {
    navLinks.push({
      name: "Daily Fixed Summary",
      path: `/patients/${id}/bgl-analysis/daily-fixed-summary/${
        page !== undefined ? page : 1
      }`,
    });
  }

  if (FEATURE_FLAG_APEX_CHART) {
    navLinks.push({
      name: "Apex Chart Spike",
      path: `/patients/${id}/bgl-analysis/apex-chart-spike/1`,
    });
  }

  if (FEATURE_FLAG_BGM_SUBTAB) {
    navLinks.push({
      name: "BGM",
      path: `/patients/${id}/bgl-analysis/bgm`,
    });
  }

  return navLinks;
}
