import styled from "styled-components/macro";
import { lightShadowAlt } from "../../../../../../../styles/global-style";
import {
  flexCenterSpaceBetween,
  mediumSemiBoldFont,
} from "../../../../../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../../../../../components/empty-state/styled-empty-state";

export const AGPReportResponseTimeRangesContainer = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
`;

export const AGPReportResponseTimeRangesHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }
`;

export const AGPReportResponseTimeRangesBody = styled.div`
  padding: 8px 16px;
  ${flexCenterSpaceBetween}
  height: calc(100% - 37px);

  ${EmptyStateBody} {
    margin: 0 auto;
    height: 100%;
  }
`;
