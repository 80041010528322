import styled from "styled-components/macro";
import {
  mediumRegularFont,
  mediumSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";

export const BGMResponseHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const BGMResponseHeaderSelectedDatesContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px;
  position: relative;

  p {
    margin: 0px;
    ${mediumSemiBoldFont}
  }

  span {
    position: absolute;
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
    bottom: -25px;
    right: 0px;
  }
`;
