import { useAGPReportLoadedResponse } from "../../../../context/agp-report-loaded-response-context";
import {
  AGPReportResponseTimeRangesChartContainer,
  AGPReportResponseTimeRangesChartItem,
} from "./styled-agp-report-response-time-ranges-chart";

export const AGPReportResponseTimeRangesChart = () => {
  const {
    veryHighPercentage,
    highPercentage,
    targetPercentage,
    lowPercentage,
    veryLowPercentage,
  } = useAGPReportLoadedResponse();

  return (
    <AGPReportResponseTimeRangesChartContainer>
      <AGPReportResponseTimeRangesChartItem
        className="very-high"
        $height={`${veryHighPercentage}%`}
      />
      <AGPReportResponseTimeRangesChartItem
        className="high"
        $height={`${highPercentage}%`}
      />
      <AGPReportResponseTimeRangesChartItem
        className="target"
        $height={`${targetPercentage}%`}
      />
      <AGPReportResponseTimeRangesChartItem
        className="low"
        $height={`${lowPercentage}%`}
      />
      <AGPReportResponseTimeRangesChartItem
        className="very-low"
        $height={`${veryLowPercentage}%`}
      />
    </AGPReportResponseTimeRangesChartContainer>
  );
};
