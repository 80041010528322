import { AGPReportResponse } from "./agp-report-response/agp-report-response";
import { AGPReportScreenHeader } from "./agp-report-screen-header";
import { AGPReportProviders } from "./context/agp-report-providers";

export const AGPReport = () => {
  return (
    <AGPReportProviders>
      <AGPReportScreenHeader />
      <AGPReportResponse />
    </AGPReportProviders>
  );
};
