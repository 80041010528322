import { convertCGMEntriesToReadings } from "../../../../../../../../utils/glucose-point-utils/convert-cgm-entries-to-readings";
import { calculateHighPercentage } from "../../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-high-percentage";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import { GroupCgmEntriesByDate } from "./build-group-cgm-entires-by-date";

export function highPercentageValue(
  groupCgmEntriesByDate: GroupCgmEntriesByDate[],
  maxRange: number
) {
  const highTotal = groupCgmEntriesByDate.reduce(
    (sum: number, item: GroupCgmEntriesByDate) => {
      const highPercentageTotal = calculateHighPercentage(
        convertCGMEntriesToReadings(item.readings),
        maxRange
      );

      return (sum += highPercentageTotal);
    },
    0
  );

  const highPercentage = roundTo1DecimalPlace(
    highTotal / groupCgmEntriesByDate.length
  );

  return highPercentage;
}
