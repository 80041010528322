import { PatientCGMEntryDTO } from "../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";

export type GroupCgmEntriesByDate = {
  date: string;
  readings: PatientCGMEntryDTO[];
};

export function buildGroupCGMEntriesByDate(
  patientCGMEntryDTOs: PatientCGMEntryDTO[]
) {
  const groupCgmEntriesByDate: GroupCgmEntriesByDate[] = [];

  for (const entry of patientCGMEntryDTOs) {
    const existingEntry = groupCgmEntriesByDate.find(
      (e) => e.date === entry.date
    );

    if (existingEntry) {
      existingEntry.readings.push(entry);
    } else {
      groupCgmEntriesByDate.push({
        date: entry.date,
        readings: [entry],
      });
    }
  }

  return groupCgmEntriesByDate;
}
