import { useMemo } from "react";
import { useBGMResponse } from "../../../../context/bgm-response-context";
import {
  BGMGlucoseBGChartContainer,
  BGMGlucoseBGChartItem,
} from "./styled-bgm-glucose-bg-chart";
import { getInRangeNumbers } from "../../../../../../../../utils/patient-type-utils";
import { buildGroupCGMEntriesByDate } from "../utils/build-group-cgm-entires-by-date";
import { veryHighPercentageValue } from "../utils/very-high-percentage-value";
import { highPercentageValue } from "../utils/high-percentage-value";
import { targetPercentageValue } from "../utils/target-percentage-value";
import { lowPercentageValue } from "../utils/low-percentage-value";
import { veryLowPercentageValue } from "../utils/very-low-percentage-value";

export const BGMGlucoseBGChart = () => {
  const { patientCGMEntryDTOs, patientDTO } = useBGMResponse();

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const groupCgmEntriesByDate = useMemo(
    () => buildGroupCGMEntriesByDate(patientCGMEntryDTOs),
    [patientCGMEntryDTOs]
  );

  const veryHighPercentage = useMemo(
    () => veryHighPercentageValue(groupCgmEntriesByDate),
    [groupCgmEntriesByDate]
  );

  const highPercentage = useMemo(
    () => highPercentageValue(groupCgmEntriesByDate, maxRange),
    [groupCgmEntriesByDate, maxRange]
  );

  const targetPercentage = useMemo(
    () => targetPercentageValue(groupCgmEntriesByDate, patientDTO),
    [groupCgmEntriesByDate, patientDTO]
  );

  const lowPercentage = useMemo(
    () => lowPercentageValue(groupCgmEntriesByDate, minRange),
    [groupCgmEntriesByDate, minRange]
  );

  const veryLowPercentage = useMemo(
    () => veryLowPercentageValue(groupCgmEntriesByDate),
    [groupCgmEntriesByDate]
  );

  return (
    <BGMGlucoseBGChartContainer>
      <BGMGlucoseBGChartItem
        className="very-high"
        $height={`${veryHighPercentage}%`}
      />
      <BGMGlucoseBGChartItem className="high" $height={`${highPercentage}%`} />
      <BGMGlucoseBGChartItem
        className="target"
        $height={`${targetPercentage}%`}
      />
      <BGMGlucoseBGChartItem className="low" $height={`${lowPercentage}%`} />
      <BGMGlucoseBGChartItem
        className="very-low"
        $height={`${veryLowPercentage}%`}
      />
    </BGMGlucoseBGChartContainer>
  );
};
