import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "./screens/layout/application-layout";
import { SinglePatient } from "./screens/single-patient/single-patient";
import { BglAnalysis } from "./screens/single-patient/bgl-analysis/bgl-analysis";
import { DailyAnalysis } from "./screens/single-patient/bgl-analysis/daily-analysis/daily-analysis";
import { Medications } from "./screens/single-patient/medications/medications";
import { MedicationsTable } from "./screens/single-patient/medications/medications-table/medications-table";
import { LabResults } from "./screens/single-patient/lab-results/lab-results";
import { LabResultTest } from "./screens/single-patient/lab-results/lab-result-test/lab-result-test";
import { Patients } from "./screens/patients/patients";
import {
  FEATURE_FLAG_404_NOT_FOUND,
  FEATURE_FLAG_HOME,
  FEATURE_FLAG_CHAT,
  FEATURE_FLAG_CONTENT_MODULES,
  FEATURE_FLAG_NUTRITION_GOALS,
  FEATURE_FLAG_NUTRITION_REPORT,
  FEATURE_FLAG_NUTRITION_SUMMARY_FOR_DEMO,
  FEATURE_FLAG_ORGANIZATIONS_AND_ROLES,
  FEATURE_FLAG_BGM_SUBTAB,
  FEATURE_FLAG_DAILY_FIXED_SUMMARY,
  FEATURE_FLAG_APEX_CHART,
} from "./constants/config";
import { DailyMealGraphs } from "./screens/single-patient/bgl-analysis/daily-meal-graphs/daily-meal-graphs";
import { Prescriptions } from "./screens/single-patient/medications/prescriptions/prescriptions";
import { NewPrescription } from "./screens/single-patient/medications/prescriptions/new-prescription/new-prescription";
import { Plans } from "./screens/single-patient/plans/plans";
import { CreatePlans } from "./screens/single-patient/plans/create-plans/create-plans";
import { FoodLogging } from "./screens/food-logging/food-logging";
import { ManualReadings } from "./screens/single-patient/bgl-analysis/manual-readings-graph/manual-readings";
import { PatientProfile } from "./screens/single-patient/patient-profile/patient-profile";
import { Administration } from "./screens/single-patient/administration/administration";
import { SmsOtps } from "./screens/single-patient/administration/sms-otps/sms-otps";
import { LifeActivities } from "./screens/single-patient/life-activities/life-activities";
import { DietitianReport } from "./screens/single-patient/dietitian-report/dietitian-report";
import { NutritionGoals } from "./screens/single-patient/dietitian-report/nutrition-goals/nutrition-goals";
import { AnalyticsAndReports } from "./screens/single-patient/dietitian-report/analytics-and-reports/analytics-and-reports";
import { FoodLoggingHome } from "./screens/food-logging/food-logging-home";
import { NutritionReport } from "./screens/food-logging/nutrition-report/nutrition-report";
import { NutritionReportTable } from "./screens/food-logging/nutrition-report/nutrition-report-table/nutrition-report-table";
import { NutritionReportSummary } from "./screens/food-logging/nutrition-report/nutrition-report-summary/nutrition-report-summary";
import { Chat } from "./screens/chat/chat";
import { ChatHome } from "./screens/chat/chat-home/chat-home";
import { Organizations } from "./screens/organizations/organizations";
import { OrganizationsTable } from "./screens/organizations/organizations-table/organizations-table";
import { Organization } from "./screens/organizations/organization/organization";
import { OrganizationDetails } from "./screens/organizations/organization/organization-details/organization-details";
import { OrganizationUsers } from "./screens/organizations/organization/organization-users/organization-users";
import { OrganizationUsersTable } from "./screens/organizations/organization/organization-users/organization-users-table/organization-users-table";
import { FourZeroFourNotFound } from "./screens/404-not-found/404-not-found";
import { PatientMealImagesModal } from "./screens/single-patient/bgl-analysis/daily-analysis/daily-charts/daily-chart/patient-meal-images-modal/patient-meal-images-modal";
import { MealsAnalysis } from "./screens/single-patient/bgl-analysis/meals-analysis/meals-analysis";
import { NutritionAnalysis } from "./screens/single-patient/dietitian-report/nutrition-analysis/nutrition-analysis";
import { Content } from "./screens/content/content";
import { ContentCourses } from "./screens/content/content-courses/content-courses";
import { CreateCourse } from "./screens/content/content-courses/create-course/create-course";
import { CreateModule } from "./screens/content/content-course-modules/create-module/create-module";
import { ContentCourseModule } from "./screens/content/content-course-modules/content-course-module/content-course-module";
import { CreateLesson } from "./screens/content/content-course-module-lessons/create-lesson/create-lesson";
import { ContentCourseModuleLesson } from "./screens/content/content-course-module-lessons/content-course-module-lesson/content-course-module-lesson";
import { ContentCourse } from "./screens/content/content-courses/content-course/content-course";
import { Home } from "./screens/home/home";
import { ImmediateAttentionPatients } from "./screens/home/immediate-attention-patients/immediate-attention-patients";
import { Bgm } from "./screens/single-patient/bgl-analysis/bgm/bgm";
import { DailyFixedSummary } from "./screens/single-patient/bgl-analysis/daily-fixed-summary/daily-fixed-summary";
import { ApexChartSpike } from "./screens/single-patient/bgl-analysis/apex-chart-spike/apex-chart-spike";
import { AGPReport } from "./screens/single-patient/bgl-analysis/agp-report/agp-report";

export function AuthenticatedApp() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="/1" replace={true} />} />
        <Route path="/:page" element={<Patients />} />
        {FEATURE_FLAG_CONTENT_MODULES && (
          <Route path="/content" element={<Content />}>
            <Route index element={<Navigate to="courses/1" replace={true} />} />
            <Route path="courses/:coursesPage" element={<ContentCourses />} />
            <Route
              path="courses/create-new-course"
              element={<CreateCourse />}
            />
            <Route
              path="courses/course/:courseID"
              element={<ContentCourse />}
            />
            <Route
              path="courses/course/:courseID/update"
              element={<ContentCourse />}
            />
            <Route
              path="courses/course/:courseID/module/create-new-module"
              element={<CreateModule />}
            />
            <Route
              path="courses/course/:courseID/module/:moduleID"
              element={<ContentCourseModule />}
            />
            <Route
              path="courses/course/:courseID/module/:moduleID/update"
              element={<ContentCourseModule />}
            />
            <Route
              path="courses/course/:courseID/module/:moduleID/create-new-lesson"
              element={<CreateLesson />}
            />
            <Route
              path="courses/course/:courseID/module/:moduleID/lesson/:lessonID"
              element={<ContentCourseModuleLesson />}
            />
            <Route
              path="courses/course/:courseID/module/:moduleID/lesson/:lessonID/update"
              element={<ContentCourseModuleLesson />}
            />
          </Route>
        )}
        {FEATURE_FLAG_ORGANIZATIONS_AND_ROLES && (
          <Route path="/organizations" element={<Organizations />}>
            <Route
              index
              element={<Navigate to="organizations-table/1" replace={true} />}
            />
            <Route
              path="organizations-table/:page"
              element={<OrganizationsTable />}
            />
            <Route
              path="organization/:organizationID"
              element={<Organization />}
            >
              <Route index element={<Navigate to="details" replace={true} />} />
              <Route path="details" element={<OrganizationDetails />} />
              <Route path="users" element={<OrganizationUsers />}>
                <Route
                  index
                  element={<Navigate to="users/1" replace={true} />}
                />
                <Route
                  path="users/:page"
                  element={<OrganizationUsersTable />}
                />
              </Route>
            </Route>
          </Route>
        )}
        <Route path="/patients/:id" element={<SinglePatient />}>
          <Route path="patient-profile" element={<PatientProfile />} />
          <Route
            index
            element={<Navigate to="bgl-analysis" replace={true} />}
          />
          <Route path="bgl-analysis" element={<BglAnalysis />}>
            <Route
              index
              element={<Navigate to="daily-analysis/1" replace={true} />}
            />
            <Route path="daily-analysis/:page" element={<DailyAnalysis />} />
            <Route
              path="patient-meal-images/:patientMealId"
              element={<PatientMealImagesModal />}
            />
            {!FEATURE_FLAG_NUTRITION_SUMMARY_FOR_DEMO && (
              <Route path="daily-meal-graphs" element={<DailyMealGraphs />} />
            )}
            <Route path="manual-readings" element={<ManualReadings />} />
            <Route path="meals-analysis" element={<MealsAnalysis />} />
            <Route path="agp-report" element={<AGPReport />} />
            {FEATURE_FLAG_DAILY_FIXED_SUMMARY && (
              <Route
                path="daily-fixed-summary/:page"
                element={<DailyFixedSummary />}
              />
            )}
            {FEATURE_FLAG_APEX_CHART && (
              <Route
                path="apex-chart-spike/:page"
                element={<ApexChartSpike />}
              />
            )}
            {FEATURE_FLAG_BGM_SUBTAB && <Route path="bgm" element={<Bgm />} />}
          </Route>
          <Route path="medications" element={<Medications />}>
            <Route index element={<Navigate to="table/1" replace={true} />} />
            <Route path="table/:page" element={<MedicationsTable />} />
            <Route path="prescriptions" element={<Prescriptions />} />
          </Route>
          <Route path="lab-results" element={<LabResults />}>
            <Route
              index
              element={<Navigate to="diabetic-profile" replace={true} />}
            />
            <Route path="diabetic-profile" element={<LabResultTest />} />
            <Route path="lipid-profile" element={<LabResultTest />} />
            <Route path="cbc" element={<LabResultTest />} />
            <Route path="kidney-functions" element={<LabResultTest />} />
            <Route path="liver-functions" element={<LabResultTest />} />
            <Route path="others" element={<LabResultTest />} />
          </Route>
          {FEATURE_FLAG_NUTRITION_GOALS && (
            <Route path="dietitian-report" element={<DietitianReport />}>
              <Route
                index
                element={<Navigate to="nutrition-goals" replace={true} />}
              />
              <Route path="nutrition-goals" element={<NutritionGoals />} />
              <Route
                path="nutrition-reports"
                element={<AnalyticsAndReports />}
              />
              <Route
                path="nutrition-analysis"
                element={<NutritionAnalysis />}
              />
            </Route>
          )}
          <Route path="plans">
            <Route
              index
              element={<Navigate to="weekly-plans" replace={true} />}
            />
            <Route path="weekly-plans" element={<Plans />} />
          </Route>
          <Route path="plans/:planID" element={<CreatePlans />} />
          <Route path="administration" element={<Administration />}>
            <Route index element={<Navigate to="sms-otps" replace={true} />} />
            <Route path="sms-otps" element={<SmsOtps />} />
          </Route>
          <Route path="life-activities" element={<LifeActivities />} />
          <Route
            path="prescriptions/new-prescription"
            element={<NewPrescription />}
          />
        </Route>
        <Route path="/food-logging" element={<FoodLogging />}>
          <Route
            index
            element={<Navigate to="food-logging-home" replace={true} />}
          />
          <Route path="food-logging-home" element={<FoodLoggingHome />} />
          {FEATURE_FLAG_NUTRITION_REPORT && (
            <Route
              path="food-logging-nutrition-report/:patientID"
              element={<NutritionReport />}
            >
              <Route
                index
                element={<Navigate to="report-table" replace={true} />}
              />
              <Route path="report-table" element={<NutritionReportTable />} />
              <Route
                path="report-summary"
                element={<NutritionReportSummary />}
              />
            </Route>
          )}
        </Route>
        {FEATURE_FLAG_CHAT && (
          <Route path="/chat" element={<Chat />}>
            <Route index element={<Navigate to="home" replace={true} />} />
            <Route path="home" element={<ChatHome />} />
          </Route>
        )}
        {FEATURE_FLAG_HOME && (
          <Route path="/home" element={<Home />}>
            <Route
              index
              element={
                <Navigate to="immediate-attention-patients/1" replace={true} />
              }
            />
            <Route
              path="immediate-attention-patients/:immediateAttentionPatientsPage"
              element={<ImmediateAttentionPatients />}
            />
            <Route
              path="all-patients/:immediateAttentionPatientsPage"
              element={<ImmediateAttentionPatients allPatinets />}
            />
          </Route>
        )}
        {FEATURE_FLAG_404_NOT_FOUND && (
          <Route path="*" element={<FourZeroFourNotFound />} />
        )}
      </Route>
    </Routes>
  );
}
