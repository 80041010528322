import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../../../../../components/empty-state/styled-empty-state";

export const BGMGlucoseBGBody = styled.div`
  padding: 16px;
  ${flexCenterSpaceBetween}
  height: calc(100% - 37px);
  width: 50%;
  margin: 0 auto;

  ${EmptyStateBody} {
    margin: 0 auto;
    height: 100%;
  }

  @media screen and (max-width: 1440px) {
    width: 70%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
