import { convertCGMEntriesToReadings } from "../../../../../../../../utils/glucose-point-utils/convert-cgm-entries-to-readings";
import { calculateVeryLowPercentage } from "../../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-very-low-percentage";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import { GroupCgmEntriesByDate } from "./build-group-cgm-entires-by-date";

export function veryLowPercentageValue(
  groupCgmEntriesByDate: GroupCgmEntriesByDate[]
) {
  const veryLowTotal = groupCgmEntriesByDate.reduce(
    (sum: number, item: GroupCgmEntriesByDate) => {
      const veryLowPercentageTotal = calculateVeryLowPercentage(
        convertCGMEntriesToReadings(item.readings)
      );

      return (sum += veryLowPercentageTotal);
    },
    0
  );

  const veryLowPercentage = roundTo1DecimalPlace(
    veryLowTotal / groupCgmEntriesByDate.length
  );

  return veryLowPercentage;
}
