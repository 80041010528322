import { useMemo } from "react";
import { getInRangeNumbers } from "../../../../../../../../utils/patient-type-utils";
import { useBGMResponse } from "../../../../context/bgm-response-context";
import {
  BGMGlucoseBGDetailsContainer,
  BGMGlucoseBGDetailsItem,
} from "./styled-bgm-glucose-bg-details";
import { buildGroupCGMEntriesByDate } from "../utils/build-group-cgm-entires-by-date";
import { veryHighPercentageValue } from "../utils/very-high-percentage-value";
import { highPercentageValue } from "../utils/high-percentage-value";
import { targetPercentageValue } from "../utils/target-percentage-value";
import { lowPercentageValue } from "../utils/low-percentage-value";
import { veryLowPercentageValue } from "../utils/very-low-percentage-value";

export const GlucoseBGDetails = () => {
  const { patientCGMEntryDTOs, patientDTO } = useBGMResponse();

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const groupCgmEntriesByDate = useMemo(
    () => buildGroupCGMEntriesByDate(patientCGMEntryDTOs),
    [patientCGMEntryDTOs]
  );

  const veryHighPercentage = useMemo(
    () => veryHighPercentageValue(groupCgmEntriesByDate),
    [groupCgmEntriesByDate]
  );

  const highPercentage = useMemo(
    () => highPercentageValue(groupCgmEntriesByDate, maxRange),
    [groupCgmEntriesByDate, maxRange]
  );

  const targetPercentage = useMemo(
    () => targetPercentageValue(groupCgmEntriesByDate, patientDTO),
    [groupCgmEntriesByDate, patientDTO]
  );

  const lowPercentage = useMemo(
    () => lowPercentageValue(groupCgmEntriesByDate, minRange),
    [groupCgmEntriesByDate, minRange]
  );

  const veryLowPercentage = useMemo(
    () => veryLowPercentageValue(groupCgmEntriesByDate),
    [groupCgmEntriesByDate]
  );

  return (
    <BGMGlucoseBGDetailsContainer>
      <BGMGlucoseBGDetailsItem $height={`${veryHighPercentage}%`}>
        <p>
          Very High <span>{`>`}250 mg/dl</span>
        </p>
        <strong>{veryHighPercentage}%</strong>
      </BGMGlucoseBGDetailsItem>
      <BGMGlucoseBGDetailsItem $height={`${highPercentage}%`}>
        <p>
          High <span>181 - 250 mg/dl</span>
        </p>
        <strong>{highPercentage}%</strong>
      </BGMGlucoseBGDetailsItem>
      <BGMGlucoseBGDetailsItem $height={`${targetPercentage}%`}>
        <p>
          Target <span>70 - 180 mg/dl</span>
        </p>
        <strong>{targetPercentage}%</strong>
      </BGMGlucoseBGDetailsItem>
      <BGMGlucoseBGDetailsItem $height={`${lowPercentage}%`}>
        <p>
          Low <span>54 - 69 mg/dl</span>
        </p>
        <strong>{lowPercentage}%</strong>
      </BGMGlucoseBGDetailsItem>
      <BGMGlucoseBGDetailsItem $height={`${veryLowPercentage}%`}>
        <p>
          Very Low <span>{`<`} 54</span>
        </p>
        <strong>{veryLowPercentage}%</strong>
      </BGMGlucoseBGDetailsItem>
    </BGMGlucoseBGDetailsContainer>
  );
};
