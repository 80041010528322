import {
  AGPReportResponseTimeRangesBody,
  AGPReportResponseTimeRangesContainer,
  AGPReportResponseTimeRangesHeader,
} from "./styled-agp-report-response-time-ranges";
import { AGPReportResponseTimeRangesChart } from "./agp-report-response-time-ranges-chart/agp-report-response-time-ranges-chart";
import { AGPReportResponseTimeRangesDetails } from "./agp-report-response-time-ranges-details/agp-report-response-time-ranges-details";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { useAGPReportLoadedResponse } from "../../../context/agp-report-loaded-response-context";

export const AGPReportResponseTimeRanges = () => {
  const {
    patientCGMEntryDTOs,
    groupCgmEntriesByDate,
  } = useAGPReportLoadedResponse();

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <AGPReportResponseTimeRangesContainer>
        <AGPReportResponseTimeRangesHeader>
          <p>Time Ranges</p>
        </AGPReportResponseTimeRangesHeader>
        <AGPReportResponseTimeRangesBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </AGPReportResponseTimeRangesBody>
      </AGPReportResponseTimeRangesContainer>
    );
  }

  if (groupCgmEntriesByDate.length < 14) {
    return (
      <AGPReportResponseTimeRangesContainer>
        <AGPReportResponseTimeRangesHeader>
          <p>Time Ranges</p>
        </AGPReportResponseTimeRangesHeader>
        <AGPReportResponseTimeRangesBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="Please select a date range of 14 days or more to proceed."
            title=""
          />
        </AGPReportResponseTimeRangesBody>
      </AGPReportResponseTimeRangesContainer>
    );
  }

  return (
    <AGPReportResponseTimeRangesContainer>
      <AGPReportResponseTimeRangesHeader>
        <p>Time Ranges</p>
      </AGPReportResponseTimeRangesHeader>
      <AGPReportResponseTimeRangesBody>
        <AGPReportResponseTimeRangesChart />
        <AGPReportResponseTimeRangesDetails />
      </AGPReportResponseTimeRangesBody>
    </AGPReportResponseTimeRangesContainer>
  );
};
