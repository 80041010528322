import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  regularRegularFont,
  regularSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";

export const DevelopmentModalButton = styled.button`
  position: absolute;
  width: 100px;
  height: 100%;
  cursor: pointer;
  left: 0px;
  top: 0px;
  background: transparent;
`;

export const DevelopmentModalContainer = styled.div`
  div {
    ${flexCenterSpaceBetween}
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

    p {
      ${regularSemiBoldFont}
    }

    span {
      ${regularRegularFont}
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;
