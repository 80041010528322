import { PatientDTO } from "../../../../../../../../models/patient-dtos/patient-dto";
import { convertCGMEntriesToReadings } from "../../../../../../../../utils/glucose-point-utils/convert-cgm-entries-to-readings";
import { calculateInRangePercentage } from "../../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-in-range-percentage";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import { GroupCgmEntriesByDate } from "./build-group-cgm-entires-by-date";

export function targetPercentageValue(
  groupCgmEntriesByDate: GroupCgmEntriesByDate[],
  patientDTO: PatientDTO
) {
  const inRangesTotal = groupCgmEntriesByDate.reduce(
    (sum: number, item: GroupCgmEntriesByDate) => {
      const inRangePercentageTotal = calculateInRangePercentage(
        patientDTO.type,
        convertCGMEntriesToReadings(item.readings)
      );

      return (sum += inRangePercentageTotal);
    },
    0
  );

  const targetPercentage = roundTo1DecimalPlace(
    inRangesTotal / groupCgmEntriesByDate.length
  );

  return targetPercentage;
}
