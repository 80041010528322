import { DateRangeProvider } from "../../../../components/date-range/context/date-range-context";
import { BgmResponseHeader } from "./bgm-response-header/bgm-response-header";
import { BGMResponse } from "./bgm-response/bgm-response";
import { BgmScreenHeader } from "./bgm-screen-header";

export const Bgm = () => {
  return (
    <DateRangeProvider>
      <BgmScreenHeader />
      <BgmResponseHeader />
      <BGMResponse />
    </DateRangeProvider>
  );
};
