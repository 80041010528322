import { useMemo } from "react";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { useAGPReportLoadedResponse } from "../../context/agp-report-loaded-response-context";
import {
  AGPReportResponseChartsBody,
  AGPReportResponseChartsCard,
  AGPReportResponseChartsContainer,
  AGPReportResponseChartsHeader,
} from "./styled-agp-report-response-charts";
import { AGPReportResponseChart } from "./agp-report-response-chart/agp-report-response-chart";

export const AGPReportResponceCharts = () => {
  const { groupCgmEntriesByDate } = useAGPReportLoadedResponse();

  const maxYAxisValue = useMemo(() => {
    return Math.max(
      ...groupCgmEntriesByDate
        .map((entry) => entry.readings)
        .flat()
        .map((glucoseValue) => glucoseValue.glucoseMGPerDL)
    );
  }, [groupCgmEntriesByDate]);

  if (groupCgmEntriesByDate.length === 0) {
    return (
      <AGPReportResponseChartsContainer>
        <AGPReportResponseChartsCard>
          <AGPReportResponseChartsHeader>
            <p>Daily Glucose Profiles</p>
          </AGPReportResponseChartsHeader>
          <AGPReportResponseChartsBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="No Data Within the selected dates"
              title=""
            />
          </AGPReportResponseChartsBody>
        </AGPReportResponseChartsCard>
      </AGPReportResponseChartsContainer>
    );
  }

  if (groupCgmEntriesByDate.length < 14) {
    return (
      <AGPReportResponseChartsContainer>
        <AGPReportResponseChartsCard>
          <AGPReportResponseChartsHeader>
            <p>Daily Glucose Profiles</p>
          </AGPReportResponseChartsHeader>
          <AGPReportResponseChartsBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="Please select a date range of 14 days or more to proceed."
              title=""
            />
          </AGPReportResponseChartsBody>
        </AGPReportResponseChartsCard>
      </AGPReportResponseChartsContainer>
    );
  }

  return (
    <AGPReportResponseChartsContainer>
      <AGPReportResponseChartsCard>
        <AGPReportResponseChartsHeader>
          <p>Daily Glucose Profiles</p>
        </AGPReportResponseChartsHeader>
        <AGPReportResponseChartsBody $isEmpty={false}>
          {groupCgmEntriesByDate.map((entry, index) => (
            <AGPReportResponseChart
              key={index}
              date={entry.date}
              patientCGMEntryDTOs={entry.readings}
              showWeekDays={index <= 6}
              showYAxisValues={index % 7 === 0}
              maxYAxisValue={maxYAxisValue > 200 ? maxYAxisValue + 50 : 250}
            />
          ))}
        </AGPReportResponseChartsBody>
      </AGPReportResponseChartsCard>
    </AGPReportResponseChartsContainer>
  );
};
