import styled from "styled-components/macro";
import { lightShadowAlt } from "../../../../../../../styles/global-style";
import {
  lgBoldFont,
  mediumSemiBoldFont,
  regularSemiBoldFont,
} from "../../../../../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../../../../../components/empty-state/styled-empty-state";

export const AGPReportResponseGlucoseStatisticsContainer = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
`;

export const AGPReportResponseGlucoseStatisticsHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }
`;

export const AGPReportResponseGlucoseStatisticsBody = styled.div`
  height: calc(100% - 37px);
  padding: 0px 16px;

  ${EmptyStateBody} {
    height: 100%;
  }
`;

export const AGPReportResponseGlucoseStatisticsBodyItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding-bottom: 16px;

  span {
    ${lgBoldFont}

    span {
      ${regularSemiBoldFont}
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export const AGPReportResponseGlucoseStatisticsBodyItemTitle = styled.p`
  ${regularSemiBoldFont}

  span {
    ${mediumSemiBoldFont}
    display: block;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
