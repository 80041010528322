import { useAGPReportLoadedResponse } from "../../../../context/agp-report-loaded-response-context";
import {
  AGPReportResponseTimeRangesDetailsContainer,
  AGPReportResponseTimeRangesDetailsItem,
} from "./styled-agp-report-response-time-ranges-details";

export const AGPReportResponseTimeRangesDetails = () => {
  const {
    veryHighPercentage,
    highPercentage,
    targetPercentage,
    lowPercentage,
    veryLowPercentage,
  } = useAGPReportLoadedResponse();

  return (
    <AGPReportResponseTimeRangesDetailsContainer>
      <AGPReportResponseTimeRangesDetailsItem
        $height={`${veryHighPercentage}%`}
      >
        <p>
          Very High <span>{`>`}250 mg/dl</span>
        </p>
        <strong>{veryHighPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
      <AGPReportResponseTimeRangesDetailsItem $height={`${highPercentage}%`}>
        <p>
          High <span>181 - 250 mg/dl</span>
        </p>
        <strong>{highPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
      <AGPReportResponseTimeRangesDetailsItem $height={`${targetPercentage}%`}>
        <p>
          Target <span>70 - 180 mg/dl</span>
        </p>
        <strong>{targetPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
      <AGPReportResponseTimeRangesDetailsItem $height={`${lowPercentage}%`}>
        <p>
          Low <span>54 - 69 mg/dl</span>
        </p>
        <strong>{lowPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
      <AGPReportResponseTimeRangesDetailsItem $height={`${veryLowPercentage}%`}>
        <p>
          Very Low <span>{`<`} 54</span>
        </p>
        <strong>{veryLowPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
    </AGPReportResponseTimeRangesDetailsContainer>
  );
};
