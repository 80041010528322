import { convertCGMEntriesToReadings } from "../../../../../../../../utils/glucose-point-utils/convert-cgm-entries-to-readings";
import { calculateVeryHighPercentage } from "../../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-very-high-percentage";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import { GroupCgmEntriesByDate } from "./build-group-cgm-entires-by-date";

export function veryHighPercentageValue(
  groupCgmEntriesByDate: GroupCgmEntriesByDate[]
) {
  const veryHighTotal = groupCgmEntriesByDate.reduce(
    (sum: number, item: GroupCgmEntriesByDate) => {
      const veryHighPercentageTotal = calculateVeryHighPercentage(
        convertCGMEntriesToReadings(item.readings)
      );

      return (sum += veryHighPercentageTotal);
    },
    0
  );

  const veryHighPercentage = roundTo1DecimalPlace(
    veryHighTotal / groupCgmEntriesByDate.length
  );

  return veryHighPercentage;
}
