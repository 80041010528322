import Chart from "react-apexcharts";
import { generateXAxis } from "./utils/generate-x-axis";
import { generateYAxisAnnotations } from "./utils/generate-y-axis-annotations";
import { generateCgmSeries } from "./utils/series/generate-cgm-series";
import { useMemo } from "react";
import { generateInsulinSeries } from "./utils/series/generate-insulin-series";
import { DayAggregate } from "../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { generatePatientMealsSeries } from "./utils/series/generate-patient-meals-series";
import { generateYAxix } from "./utils/generate-y-axis";
import { generateApexChartTooltip } from "./utils/apex-chart-tooltip/generate-apex-chart-tooltip";
import { generateChartPoints } from "./utils/points/generate-chart-points";
import { generateActivitesSeries } from "./utils/series/generate-activities-series";

type CgmApexChartProps = {
  dayAggregate: DayAggregate;
};

export const CgmApexChart = (props: CgmApexChartProps) => {
  const { dayAggregate } = props;

  const patientCGMEntryDTOs = dayAggregate.patientCGMEntryDTOs;
  const patientInsulinLogDTOs = dayAggregate.patientInsulinLogDTOs;
  const patientMealDTOs = dayAggregate.patientMealDTO;
  const patientActivityLogDTOs = dayAggregate.patientActivityLogs;

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
      ),
    [patientCGMEntryDTOs]
  );

  const maxY = useMemo(() => {
    let maxGlucose = Math.max(
      ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
    );
    return maxGlucose > 200 ? maxGlucose + 50 : 250;
  }, [patientCGMEntryDTOs]);

  const cgmSeries = useMemo(() => generateCgmSeries(patientCGMEntryDTOs), [
    patientCGMEntryDTOs,
  ]);

  const insulinSeries = useMemo(
    () => generateInsulinSeries(patientCGMEntryDTOs, patientInsulinLogDTOs),
    [patientCGMEntryDTOs, patientInsulinLogDTOs]
  );

  const patientMealsSeries = useMemo(
    () => generatePatientMealsSeries(patientCGMEntryDTOs, patientMealDTOs),
    [patientCGMEntryDTOs, patientMealDTOs]
  );

  const patientActivitiesSeries = useMemo(
    () => generateActivitesSeries(patientCGMEntryDTOs, patientActivityLogDTOs),
    [patientCGMEntryDTOs, patientActivityLogDTOs]
  );

  const chartPoints = useMemo(
    () =>
      generateChartPoints(
        dayAggregate.date.toString(),
        patientCGMEntryDTOs,
        patientMealDTOs,
        patientInsulinLogDTOs,
        patientActivityLogDTOs
      ),
    [
      dayAggregate,
      patientCGMEntryDTOs,
      patientMealDTOs,
      patientInsulinLogDTOs,
      patientActivityLogDTOs,
    ]
  );

  return (
    <Chart
      type="line"
      width="700"
      height="180"
      options={{
        tooltip: generateApexChartTooltip(patientCGMEntryDTOs),
        chart: {
          id: "basic-bar",
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        annotations: {
          points: chartPoints,
          yaxis: generateYAxisAnnotations(
            minY,
            maxY,
            dayAggregate.inRangeNumbers
          ),
        },
        yaxis: generateYAxix(minY, maxY, dayAggregate.inRangeNumbers),
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: generateXAxis(dayAggregate.date.toString()),
        dataLabels: {
          enabled: false,
        },
      }}
      series={[
        {
          name: "CGM",
          data: cgmSeries,
          color: "#686868",
          type: "line",
        },
        {
          name: "Insulin",
          data: insulinSeries,
          color: "#4b39a8",
          type: "bubble",
        },
        {
          name: "Patient Meals",
          data: patientMealsSeries,
          color: "#03cc83",
          type: "bubble",
        },
        {
          name: "Patient Activities",
          data: patientActivitiesSeries,
          color: "#020a6b",
          type: "bubble",
        },
      ]}
    />
  );
};
