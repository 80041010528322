import { Temporal } from "temporal-polyfill";
import { useDateRange } from "../../../../../components/date-range/context/date-range-context";
import { DateRange } from "../../../../../components/date-range/date-range";
import {
  BGMResponseHeaderContainer,
  BGMResponseHeaderSelectedDatesContainer,
} from "./styled-bgm-response-header";

export const BgmResponseHeader = () => {
  const { selectedDates } = useDateRange();

  const selectedStartDate = Temporal.PlainDate.from(
    selectedDates.temporalStartDate
  ).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: undefined,
  });

  const selectedEndDate = Temporal.PlainDate.from(
    selectedDates.temporalEndDate
  ).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <BGMResponseHeaderContainer>
      <DateRange />
      <BGMResponseHeaderSelectedDatesContainer>
        <p>
          Selected Dates:{" "}
          {`${selectedStartDate} - ${selectedEndDate} (${selectedDates.count} Days)`}
        </p>
      </BGMResponseHeaderSelectedDatesContainer>
    </BGMResponseHeaderContainer>
  );
};
