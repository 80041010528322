import {
  NavigationLeftContainerMobile,
  NavigationMenuIcon,
  NavigationNav,
  NavigationUserDetails,
  NavigationUserImage,
  NavigationUserInfo,
} from "./styled-navigation";
import { useUser } from "../../../context/auth-context/auth-context";
import { OtidaLogo } from "../../../styles/atoms/otida-logo";
import { DevelopmentModal } from "./development-modal/development-modal";
import { FEATURE_FLAG_DEVELOPMENT_MODAL } from "../../../constants/config";

type NavigationProps = {
  showResponsiveSideber: boolean;
  handleShowResponsiveSideber: () => void;
};

export const Navigation = (props: NavigationProps) => {
  const { showResponsiveSideber, handleShowResponsiveSideber } = props;

  const user = useUser();

  return (
    <NavigationNav>
      {FEATURE_FLAG_DEVELOPMENT_MODAL && <DevelopmentModal />}
      {/* Mobile View */}
      <NavigationLeftContainerMobile>
        <NavigationMenuIcon
          showResponsiveSideber={showResponsiveSideber}
          onClick={handleShowResponsiveSideber}
        >
          <span className="material-symbols-outlined">menu</span>
        </NavigationMenuIcon>
        <OtidaLogo navLogo />
      </NavigationLeftContainerMobile>
      {/* Mobile View */}
      <NavigationUserInfo>
        <NavigationUserImage>
          {/* I have no idea why. But without `referrerPolicy`, it won't work in development/on localhost */}
          {/* See: https://stackoverflow.com/a/61042200/6690391 */}
          <img
            src={
              user.imageUrl === undefined ? "/img/logo-2.svg" : user.imageUrl
            }
            alt="User Profile"
            referrerPolicy="no-referrer"
          />
        </NavigationUserImage>
        <NavigationUserDetails>
          <h4>{user.name}</h4>
        </NavigationUserDetails>
      </NavigationUserInfo>
    </NavigationNav>
  );
};
